const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
})

$(document).on("turbolinks:load", function() {
  jQuery(function ($) {
    $(".show_detail").click(function() {
      $.get( "/loans/show_detail?loan_id=" + $(this).data("id"), function(data) {
        $(".modal-title").text(`#${data.loan_number} Details`);
        $(".modal-body").html(
          `
          <h5 class="text-muted">Borrower:</h5>
          <span>
            <strong>${data.borrower_name}</strong><br>
            Franchise name: ${data.franchise_name}<br>
            ${data.borrower_address}<br>
            ${data.borrower_city}, ${data.borrower_state} ${data.borrower_zip}<br>
            ${data.business_type}<br>
            Jobs reported: ${data.jobs_reported}<br>
            <small>${data.business_age_description}</small>
          </span>

          <h5 class="mt-3">Loan approved on: ${data.date_approved} <span class="text-muted">status: ${data.loan_status}</span></h5>
          <span>
            Term: ${data.term}<br>
            Initial Approval Amount: ${formatter.format(data.initial_approval_amount)}<br>
            Current Approval Amount: ${formatter.format(data.current_approval_amount)}<br>
            Undisbursed Amount: ${formatter.format(data.undisbursed_amount)}
          </span>

          <h5 class="mt-3">Proceeds:</h5>
          <span>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Proceeds</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Utilities</td>
                  <td>${formatter.format(data.utilities_proceed)}</td>
                </tr>
                <tr>
                  <td>Payroll</td>
                  <td>${formatter.format(data.payroll_proceed)}</td>
                </tr>
                <tr>
                  <td>Mortgage Interest</td>
                  <td>${formatter.format(data.mortgage_interest_proceed)}</td>
                </tr>
                <tr>
                  <td>Rent</td>
                  <td>${formatter.format(data.rent_proceed)}</td>
                </tr>
                <tr>
                  <td>Refinance EIDL</td>
                  <td>${formatter.format(data.refinance_eidl_proceed)}</td>
                </tr>
                <tr>
                  <td>Health Care</td>
                  <td>${formatter.format(data.health_care_proceed)}</td>
                </tr>
                <tr>
                  <td>Debt Interest</td>
                  <td>${formatter.format(data.debt_interest_proceed)}</td>
                </tr>
                <tr>
                <td>Proceeds Total</td>
                <td>${formatter.format(data.proceeds_total)}</td>
              </tr
              </tbody>
            </table>
          </span>

          <h5 class="mt-3">Servicing Lender (Location ID ${data.servicing_lender_location_id})</h5>
          <span>
            <strong>${data.servicing_lender_name}</strong><br>
            ${data.servicing_lender_address}<br>
            ${data.servicing_lender_city}, ${data.servicing_lender_state} ${data.servicing_lender_zip}<br>
          </span>

          <h5 class="mt-3">Originating Lender (Location ID ${data.originating_lender_location_id})</h5>
          <span>
            <strong>${data.originating_lender_name}</strong><br>
            ${data.originating_lender_address}<br>
            ${data.originating_lender_city}, ${data.originating_lender_state} ${data.originating_lender_zip}<br>
          </span>
          `
        );
      });
    });
  });
})
